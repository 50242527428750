.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: black;
    color: white;
    border: unset;
  }
.CalendarDay__default{
  position: unset;
}

  .CalendarDay__selected_span {
    background: black;
    color: white !important;
  }

  /* .DateRangePicker_picker__directionLeft {
      z-index: 1000;
  } */


  .DateInput {
    width: 100px;
  }
  .DateInput__block {
    width: 85%;
  }
  .DateInput_input {
    font-size: 11px;
    font-weight: 300;
    line-height: 27px;
    padding: 6px 15px 3px;
  }
  .DateInput__small {
    width: 95px;
    border-bottom: 0.5px solid #ccc;
  }
  .SingleDatePicker_picker {
    z-index: 20;
  }
  .SingleDatePickerInput {
    border: 1px #EBEBEB solid;
  }
  .DateRangePickerInput__showClearDates {
      padding-right: 10px;
  }

  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid black;
  }

  .DateInput_input__focused {
      border-bottom: none;
  }


.react-grid-Main {
  outline: none !important;
  overflow: hidden !important;
  overflow-x: hidden !important;
}

.react-grid-Container {
  margin-bottom: 10px;
  overflow-x: hidden !important;
}

.react-grid-Grid {
  background: #fff !important;
  color: black !important;
  overflow-x: hidden !important;
}

.react-grid-Canvas {
  background: #fff !important;
}

.react-grid-Header {
  background: #fff !important;
  font-weight: 300 !important;
}

.react-grid-HeaderRow {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.react-grid-HeaderCell {
  background: #fff !important;
  font-weight: 500 !important;
}

.react-grid-Cell {
  border: 1px solid #ddd;
  background: #fff !important;
  font-weight: 300 !important;
}

.react-grid-Cell:not(.editing):not(.rdg-child-cell) .react-grid-Cell__value {
  transform: none !important;
  margin-top: -12px;
}

.react-grid-Cell:focus {
  outline: none !important;
}

.react-grid-Row {
  height: 30px !important;
  font-size: 12px !important;
  cursor: pointer !important;
}

.g-dropDown {
  border: 1px solid #ebebeb;
  color: #999999;
  font-size: 0.7em;
  font-weight: 300;
  height: 40px;
  line-height: 1;
  outline: 0;
  padding: 0.6em 10px;
}

.g-dropDown-h-font {
  color: #999999;
  font-size: 0.7em;
  font-weight: 300;
}

.icon-rotate {
  transform: rotate(180deg);
}

.alg-search-input {
  border: none !important;
  font-size: 12px !important;
  box-shadow: none !important;
}

.alg-search-input::placeholder {
  color: #bcbcbc !important;
}

.g-dropDown-items {
  border: 1px solid #ebebeb;
  box-shadow: 0 0 5px #ebebeb;
  display: flex;
  flex-direction: column;
  max-height: 550px !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px;
  z-index: 1;
}

.strikeout {
  text-decoration: line-through;
}
.Select-menu-outer {
  height: 480px !important;
  overflow-y: visible !important;
  max-height: 200px !important;
}

.Select-menu {
  max-height: 200px !important;
}

.stro-button.stro-right-button span {
  margin-top: 30px !important;
}

.Select-control {
  border-radius: 0px !important;
  border: 1px #ebebeb solid !important;
  font-size: 1.0em;
  font-weight: 300;
}
#person-input-name {
  padding: 18.5px 14px;
  height: 54px;
  font-size: 15px;
  color: '#808080'
}

@font-face {
  font-family: 'Aktiv-Grotesk';
  src: url(../assets/fonts/aktiv-grotesk-light.woff);
  font-weight: lighter;
}
@font-face {
  font-family: 'Aktiv-Grotesk';
  src: url(../assets/fonts/aktiv-grotesk-regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: 'Aktiv-Grotesk';
  src: url(../assets/fonts/aktiv-grotesk-bold.woff);
  font-weight: bold;
}
html {
  font-family: 'Aktiv-Grotesk', sans-serif;
}

.ag-theme-alpine {
  --ag-data-color: #212529 !important;
  --ag-odd-row-background-color: rgb(0, 0, 0, 0) !important;
  --ag-font-size: 12px !important;
  --ag-font-weight: lighter !important;
  --ag-header-background-color: #ffffff !important;
  --ag-font-family: 'Aktiv-Grotesk', sans-serif !important;
}

.ag-header {
  border-bottom: none !important;
}

.ag-theme-alpine .ag-row {
  cursor: pointer;
  border-bottom: none !important;
}

.ag-theme-alpine .ag-row:hover {
  color: #212529;
  background-color: #f5f5f5 !important;
}

.ag-theme-alpine .ag-cell-value {
  line-height: 20px !important;
  word-break: normal;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: lighter;
  border-color: #fbfcfc;
}

.pointer {
  cursor: pointer;
}

.c-dialog {
  display: inline-block;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
}

.c-dialog-count {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.auspices-table {
  font-size: 12px;
  font-weight: 300;
}

.positions-divider {
  border-bottom: black 1px solid;
  width: 100%;
  margin: 0.5em 0;
}

.positions-tab-settings-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.positions-adder-row {
  display: flex;
  margin-top: 10px;
}

.auspices-table-group-by-entry-container {
  display: flex;
}

.auspices-table-entry-container {
  display: flex;
  margin-bottom: 10px;
}

.auspices-table-entry-loading {
  display: flex;
  margin-bottom: 10px;
}

.loading-indicator-container {
  margin-top: 2px;
}

.positions-ellipsis {
  margin-top: 8px;
  margin-left: 8px;
  cursor: pointer;
}

.sortable-auspices-table-header {
  flex: 0.3;
  border-bottom: 1px solid black;
  cursor: pointer;
}

.unsortable-auspices-table-header {
  flex: 0.17;
  border-bottom: 1px solid black;
}

.auspices-table-header-container {
  display: flex;
  margin-bottom: 5px;
}

.auspices-table-action-menu-container {
  display: flex;
  background-color: inherit;
  height: 40px;
  align-items: center;
  margin: auto;
}

.auspices-table-action-menu-entry {
  flex: 1;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.auspices-table-modal-button {
  border: 1px solid black;
  margin-right: 10px;
  width: 50px;
}

.action-menu-icon {
  margin: 0 3px;
}

.auspices-table-entry {
  flex: 0.19;
  padding-left: 3px;
  line-height: 1.8rem;
  height: 1.8rem;
  background-color: white;
  cursor: pointer;
}

.auspices-table-client-entry {
  flex: 0.19;
  padding-left: 3px;
  line-height: 1.8rem;
  height: 1.8rem;
  background-color: white;
  cursor: pointer;
  font-weight: bold;
}

.auspices-table-archived-client-entry {
  flex: 0.19;
  padding-left: 3px;
  line-height: 1.8rem;
  height: 1.8rem;
  background-color: #f5f5f5;
  cursor: pointer;
  font-weight: bold;
  color: #bcbbbc;
}

.auspices-table-profile-link {
  color: black;
  text-decoration: underline;
}

.auspices-table-archived-profile-link {
  color: #bcbbbc;
  text-decoration: underline;
}

.auspices-table-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.auspices-table-inline-duplicate-error {
  margin-top: -10px;
}

.auspices-table-duplicate-error {
  margin-top: 5px;
}

.positions-add-save-button {
  background-color: white;
  border-radius: 0;
  border-color: black;
  font-size: 13px;
  height: 39px;
  margin-left: 1em;
  flex: 0.135;
}

.positions-adder-notes {
  flex: 0.66;
  margin-right: 3px;
}

.auspices-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.add-auspice-form-wrapper {
  position: relative;
  height: 0px;
  overflow: visible;
}

.add-auspice-form {
  position: absolute;
  left: 0px;
  right: 0px;
  padding: 30px;

  background: white;
  border: 0.5px solid black;
  border-radius: 1px;
  z-index: 1;
}

.add-auspice-form .form-done-buttons {
  margin-top: 12px;
  margin-left: auto;
  width: 100px;
}

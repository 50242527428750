/* Customize the label (the container) */
.Checkbox-container {
  display: block;
  padding-top: 2px;
  position: relative;
  font-size: 12px !important;
  text-align: center;
  align-content: center;
  align-items: center;
  font-weight: 100;
  padding-left: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.Checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.Checkbox-checkmark {
  position: absolute;
  margin-top: 3px;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: white;
  border: 1px#ebebeb solid;
}

/* On mouse-over, add a grey background color */
.Checkbox-container:hover input ~ .Checkbox-checkmark {
  background-color: black;
}

/* When the checkbox is checked, add a blue background */
.Checkbox-container input:checked ~ .Checkbox-checkmark {
  background-color: black;
}

/* Create the checkmark/indicator (hidden when not checked) */
.Checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Checkbox-container input:checked ~ .Checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.Checkbox-container .Checkbox-checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.fade:not(.show) {
  opacity: 1 !important;
}

.popover-title {
  background: #eee;
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  text-transform: uppercase;
}

#popover-positioned-bottom {
  top: 100px !important;
}